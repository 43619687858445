import React from 'react';
import { Link } from 'react-router-dom';
import Rating from './Rating';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
export default function Product(props) {
  const { product } = props;
  return (
    <Card key={product._id} className="card" >
       <CardMedia
           style = {{ height: "50%"}}
           component="img"
          src={product.image}
        />
      <CardContent>
      
        <div className="card-body">
        <Link to={`/product/${product._id}`}>
          <h2>{product.name}</h2>
        </Link>
        <Rating
          rating={product.rating}
          numReviews={product.numReviews}
        ></Rating>
        <div className="row">
          <div className="price">${product.price}</div>
          <div>
            <Link to={`/seller/${product.seller._id}`}>
              {/* {product.seller.seller.name} */}
            </Link>
          </div>
        </div>
      </div>
      </CardContent>
    </Card>
  );
}
