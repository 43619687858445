import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createOrder } from '../actions/orderActions';
import CheckoutSteps from '../components/CheckoutSteps';
import { ORDER_CREATE_RESET } from '../constants/orderConstants';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Container from '@material-ui/core/Container';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green, purple } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
export default function PlaceOrderScreen(props) {
  const cart = useSelector((state) => state.cart);
  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(purple[500]),
      size: "large",
      backgroundColor: purple[500],
      '&:hover': {
        backgroundColor: purple[700],
      },
    },
  }))(Button);
  if (!cart.paymentMethod) {
    props.history.push('/payment');
  }
  const orderCreate = useSelector((state) => state.orderCreate);
  const { loading, success, error, order } = orderCreate;
  const toPrice = (num) => Number(num.toFixed(2)); // 5.123 => "5.12" => 5.12
  cart.itemsPrice = toPrice(
    cart.cartItems.reduce((a, c) => a + c.qty * c.price, 0)
  );
  cart.shippingPrice = cart.itemsPrice > 100 ? toPrice(0) : toPrice(10);
  cart.taxPrice = toPrice(0.15 * cart.itemsPrice);
  cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice;
  const dispatch = useDispatch();
  const placeOrderHandler = () => {
    dispatch(createOrder({ ...cart, orderItems: cart.cartItems }));
  };
  useEffect(() => {
    if (success) {
      props.history.push(`/order/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
  }, [dispatch, order, props.history, success]);
  return (
    <div>
      <CheckoutSteps step1 step2 step3 step4></CheckoutSteps>
      <div className="row top">
        <div className="col-2">
          <ul>
            <li>
              <Card style={{margin:"8px"}}>
                <CardContent>
                  <Typography style={{fontWeight:"bold"}} variant="h4" gutterBottom gutterBottom>
                    Shipping
                  </Typography>
                  <Typography  variant="h5" gutterBottom>
                  <span style={{fontWeight:"bold"}}>Name:</span>
                  {cart.shippingAddress.fullName}
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                  <span style={{fontWeight:"bold"}}>Address:</span>
                  {cart.shippingAddress.address},
                  {cart.shippingAddress.city},{' '}
                  {cart.shippingAddress.postalCode},
                  {cart.shippingAddress.country}
                  </Typography>
                </CardContent>
              </Card>
            </li>
            <li>
            <Card style={{margin:"8px"}}>
                <CardContent>
                  <Typography style={{fontWeight:"bold"}} variant="h4" gutterBottom gutterBottom>
                    Payment
                  </Typography>
                  <Typography variant="h5" gutterBottom gutterBottom>
                    <span style={{fontWeight:"bold"}}>Method:</span>
                    {cart.paymentMethod}
                  </Typography>
                  </CardContent>
              </Card>
              
            </li>
            <li>
            <Card style={{margin:"8px"}}>
                <CardContent>
                  <Typography style={{fontWeight:"bold"}} variant="h4" gutterBottom gutterBottom>
                    Order Items
                  </Typography>
                  <ul>
                    {cart.cartItems.map((item) => (
                      <li key={item.product}>
                        <div className="row">
                          <div>
                            <img
                              src={item.image}
                              alt={item.name}
                              className="small"
                            ></img>
                          </div>
                          <div className="min-30">
                            <Link to={`/product/${item.product}`}>
                              {item.name}
                            </Link>
                          </div>

                          <div>
                            {item.qty} x ${item.price} = ${item.qty * item.price}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </li>
          </ul>
        </div>
        <div className="col-1">
          <Card style={{margin:"8px"}}>
            <CardContent>
              <ul>
                  <li>
                    <h2>Order Summary</h2>
                  </li>
                  <li>
                    <div className="row">
                      <div>Items</div>
                      <div>${cart.itemsPrice.toFixed(2)}</div>
                    </div>
                  </li>
                  <li>
                    <div className="row">
                      <div>Shipping</div>
                      <div>${cart.shippingPrice.toFixed(2)}</div>
                    </div>
                  </li>
                  <li>
                    <div className="row">
                      <div>Tax</div>
                      <div>${cart.taxPrice.toFixed(2)}</div>
                    </div>
                  </li>
                  <li>
                    <div className="row">
                      <div>
                        <strong> Order Total</strong>
                      </div>
                      <div>
                        <strong>${cart.totalPrice.toFixed(2)}</strong>
                      </div>
                    </div>
                  </li>
                  <li>
                    <ColorButton
                      size="large"
                      fullWidth
                      type="button"
                      onClick={placeOrderHandler}
                      disabled={cart.cartItems.length === 0}
                    >
                      Place Order
                    </ColorButton>
                  </li>
                  {loading && <LoadingBox></LoadingBox>}
                  {error && <MessageBox variant="danger">{error}</MessageBox>}
                </ul>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
