import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createReview, detailsProduct } from '../actions/productActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Rating from '../components/Rating';
import { PRODUCT_REVIEW_CREATE_RESET } from '../constants/productConstants';
import Container from '@material-ui/core/Container';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green, purple } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

export default function ProductScreen(props) {
  const dispatch = useDispatch();
  const productId = props.match.params.id;
  const [qty, setQty] = useState(1);
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(purple[500]),
      size: "large",
      backgroundColor: purple[500],
      '&:hover': {
        backgroundColor: purple[700],
      },
    },
  }))(Button);
  const ratingsValues = [
    {
      value: 1,
      label: 'Poor',
    },
    {
      value: 2,
      label: "Fair",
    },
    {
      value: 3,
      label: "Good",
    },
    {
      value: 4,
      label: "Very good",
    },
    {
      value: 5,
      label: "Excelent",
    },
  ];
  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    loading: loadingReviewCreate,
    error: errorReviewCreate,
    success: successReviewCreate,
  } = productReviewCreate;

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (successReviewCreate) {
      window.alert('Review Submitted Successfully');
      setRating('');
      setComment('');
      dispatch({ type: PRODUCT_REVIEW_CREATE_RESET });
    }
    dispatch(detailsProduct(productId));
  }, [dispatch, productId, successReviewCreate]);
  const addToCartHandler = () => {
    props.history.push(`/cart/${productId}?qty=${qty}`);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (comment && rating) {
      dispatch(
        createReview(productId, { rating, comment, name: userInfo.name })
      );
    } else {
      alert('Please enter comment and rating');
    }
  };
  return (
    <div>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div>
          <Link style={{margin:"8px"}} to="/">Back to home</Link>
          <div className="row top">

            <div className="col-2">
              <Card style={{margin:"8px"}}>
                <CardMedia>
                  <img
                    className="large"
                    src={product.image}
                    alt={product.name}
                ></img>
                </CardMedia>
              </Card>
            </div>
            <div className="col-1">
              <Card style={{margin:"8px"}}>
                <CardContent>
                  <ul>
                    <li>
                      <h1>{product.name}</h1>
                    </li>
                    <li>
                      <Rating
                        rating={product.rating}
                        numReviews={product.numReviews}
                      >
                      </Rating>
                    </li>
                    <li>Pirce : ${product.price}</li>
                    <li>
                      Description:
                      <p>{product.description}</p>
                    </li>
                </ul>
                </CardContent>
              </Card>
           
            </div>
            <div className="col-1">
              <Card style={{margin:"8px"}}>
                <CardContent>
                  <ul>
                    <li>
                      Seller{' '}
                      <h2>
                        <Link to={`/seller/${product.seller._id}`}>
                          {product.seller.seller.name}
                        </Link>
                      </h2>
                      <Rating
                        rating={product.seller.seller.rating}
                        numReviews={product.seller.seller.numReviews}
                      ></Rating>
                    </li>
                    <li>
                      <div className="row">
                        <div>Price</div>
                        <div className="price">${product.price}</div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div>Status</div>
                        <div>
                          {product.countInStock > 0 ? (
                            <span className="success">In Stock</span>
                          ) : (
                            <span className="danger">Unavailable</span>
                          )}
                        </div>
                      </div>
                    </li>
                    {product.countInStock > 0 && (
                      <>
                        <li>
                          <div className="row">
                            <div>Qty</div>
                            <div>
                              <select
                                value={qty}
                                onChange={(e) => setQty(e.target.value)}
                              >
                                {[...Array(product.countInStock).keys()].map(
                                  (x) => (
                                    <option key={x + 1} value={x + 1}>
                                      {x + 1}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                        </li>
                        <li>
                          <ColorButton fullWidth 
                            onClick={addToCartHandler}
                          >
                            Add to Cart
                          </ColorButton>
                        </li>
                      </>
                    )}
                  </ul>
                </CardContent>
              </Card>
             
            </div>
          </div>
          <div>
            <Card style={{margin:"8px"}}>
              <CardContent>
                <h2 id="reviews">Reviews</h2>
                {product.reviews.length === 0 && (
                  <MessageBox>There is no review</MessageBox>
                )}
                <ul>
                  {product.reviews.map((review) => (
                    <li key={review._id}>
                      <strong>{review.name}</strong>
                      <Rating rating={review.rating} caption=" "></Rating>
                      <p>{review.createdAt.substring(0, 10)}</p>
                      <p>{review.comment}</p>
                    </li>
                  ))}
                  <li>
                    {userInfo ? (
                      <form className="form" onSubmit={submitHandler}>
                        <div>
                          <Typography align="center" style={{fontWeight:"bold"}} variant="h4" gutterBottom gutterBottom>
                            <h2>Write a customer review</h2>
                          </Typography>
                        </div>
                        <div>
                          <TextField
                            id="rating"
                            select
                            label="Rating"
                            value={rating}
                            variant="outlined"
                            onChange={(e) => setRating(e.target.value)}
                            helperText="Please select your rating"
                            >
                              {ratingsValues.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                          </TextField>
                        </div>
                       
                        <div>
                          <TextField
                            id="comment"
                            label="Comment"
                            placeholder="write your comment here..."
                            multiline
                            value={comment}
                            variant="outlined"
                            onChange={(e) => setComment(e.target.value)}
                          />
                        </div>
                        <div>
                          <label />
                          <ColorButton fullWidth  type="submit">
                            Submit
                          </ColorButton>
                        </div>
                        <div>
                          {loadingReviewCreate && <LoadingBox></LoadingBox>}
                          {errorReviewCreate && (
                            <MessageBox variant="danger">
                              {errorReviewCreate}
                            </MessageBox>
                          )}
                        </div>
                      </form>
                    ) : (
                      <MessageBox>
                        Please <Link to="/signin">Sign In</Link> to write a review
                      </MessageBox>
                    )}
                  </li>
                </ul>
              </CardContent>
            </Card>
           
          </div>
        </div>
      )}
    </div>
  );
}
