import Axios from 'axios';
import { PayPalButton } from 'react-paypal-button-v2';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deliverOrder, detailsOrder, payOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Container from '@material-ui/core/Container';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green, purple } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from '../constants/orderConstants';

export default function OrderScreen(props) {
  const orderId = props.match.params.id;
  const [sdkReady, setSdkReady] = useState(false);
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(purple[500]),
      size: "large",
      backgroundColor: purple[500],
      '&:hover': {
        backgroundColor: purple[700],
      },
    },
  }))(Button);
  const orderPay = useSelector((state) => state.orderPay);
  const {
    loading: loadingPay,
    error: errorPay,
    success: successPay,
  } = orderPay;
  const orderDeliver = useSelector((state) => state.orderDeliver);
  const {
    loading: loadingDeliver,
    error: errorDeliver,
    success: successDeliver,
  } = orderDeliver;
  const dispatch = useDispatch();
  useEffect(() => {
    const addYocoScript = async () =>{
      var yoco = new window.YocoSDK({
        publicKey: 'pk_test_ed3c54a6gOol69qa7f45',
      });
      var checkoutButton = document.querySelector('#checkout-yoco');
      checkoutButton.addEventListener('click', function () {
        yoco.showPopup({
          amountInCents: order.totalPrice.toFixed(2) * 100,
          currency: 'ZAR',
          name: 'Payment For Order Inclusive of tax',
          description: 'Total cost inclusive of tax',
          callback: function (result) {
            // This function returns a token that your server can use to capture a payment
            if (result.error) {
              const errorMessage = result.error.message;
              alert("error occured: " + errorMessage);
            } else {
             
              alert("card successfully tokenised: " + result.id);
              successPaymentHandler();
            }
            // In a real integration - you would now pass this chargeToken back to your
            // server along with the order/basket that the customer has purchased.
          }
        })
      });
    }
    const addPayPalScript = async () => {
      const { data } = await Axios.get('/api/config/paypal');
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.paypal.com/sdk/js?client-id=${data}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };
    if (
      !order ||
      successPay ||
      successDeliver ||
      (order && order._id !== orderId)
    ) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(detailsOrder(orderId));
    } else {
      if (!order.isPaid) {
        if (!window.paypal) {
          addYocoScript();
          addPayPalScript();
        } else {
          setSdkReady(true);
        }
      }
    }
  }, [dispatch, orderId, sdkReady, successPay, successDeliver, order]);

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(order, paymentResult));
  };
  const deliverHandler = () => {
    dispatch(deliverOrder(order._id));
  };

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
     
      <Typography style={{fontWeight:"bold"}} variant="h4" gutterBottom gutterBottom>
        Order ID:  {order._id}
      </Typography>

      <div className="row top">
        <div className="col-2">
          <ul>
            <li>
              <Card >
                <CardContent>
                  <Typography style={{fontWeight:"bold"}} variant="h4" gutterBottom gutterBottom>
                    Shipping
                  </Typography>
                  <Typography  variant="h5" gutterBottom>
                  <span style={{fontWeight:"bold"}}>Name:</span>
                  {order.shippingAddress.fullName}
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                  <span style={{fontWeight:"bold"}}>Address:</span>
                  {order.shippingAddress.address},
                  {order.shippingAddress.city},{' '}
                  {order.shippingAddress.postalCode},
                  {order.shippingAddress.country}
                  </Typography>
                  {order.isDelivered ? (
                    <MessageBox variant="success">
                      Delivered at {order.deliveredAt}
                    </MessageBox>
                  ) : (
                    <MessageBox variant="danger">Not Delivered</MessageBox>
                  )}
                </CardContent>
              </Card>
            </li>
            <li>
              <Card>
                <CardContent>
                  <Typography style={{fontWeight:"bold"}} variant="h4" gutterBottom gutterBottom>
                    Payment
                  </Typography>
                  <Typography variant="h5" gutterBottom gutterBottom>
                    <span style={{fontWeight:"bold"}}>Method:</span>
                    {order.paymentMethod}
                  </Typography>
                
                  {order.isPaid ? (
                      <MessageBox variant="success">
                        Paid at {order.paidAt}
                      </MessageBox>
                      ) : (
                        <MessageBox variant="danger">Not Paid</MessageBox>
                  )}
                 
                </CardContent>
              </Card>
            </li>
            <li>
              <Card>
                <CardContent>
                  <Typography style={{fontWeight:"bold"}} variant="h4" gutterBottom gutterBottom>
                    Order Items
                  </Typography>
                  <ul>
                  {order.orderItems.map((item) => (
                    <li key={item.product}>
                      <div className="row">
                        <div>
                          <img
                            src={item.image}
                            alt={item.name}
                            className="small"
                          ></img>
                        </div>
                        <div className="min-30">
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </div>

                        <div>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                </CardContent>
              </Card>
            </li>
          </ul>
        </div>
        <div className="col-1">
          <Card style={{marginLeft:"10px", marginTop:"8px"}}>
            <CardContent>
              <ul>
                <li>
                  <h2>Order Summary</h2>
                </li>
                <li>
                  <div className="row">
                    <div>Items</div>
                    <div>${order.itemsPrice.toFixed(2)}</div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div>Shipping</div>
                    <div>${order.shippingPrice.toFixed(2)}</div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div>Tax</div>
                    <div>${order.taxPrice.toFixed(2)}</div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div>
                      <strong> Order Total</strong>
                    </div>
                    <div>
                      <strong>${order.totalPrice.toFixed(2)}</strong>
                    </div>
                  </div>
                </li>
                {!order.isPaid && (
                  <li>
                    <Button size="large" fullWidth style={{
                      display:"inline-block",  
                      backgroundColor:"#36a0db", color:"white" , 
                      padding:"10px 60px", margin: "16px 0px",
                      with:"100%",
                      boxSizing:"border-box",
                    }}
                      id="checkout-yoco">Pay with YOCO
                    </Button>
                    {!sdkReady ? (
                      <LoadingBox></LoadingBox>
                    ) : (
                      <>
                        {errorPay && (
                          <MessageBox variant="danger">{errorPay}</MessageBox>
                        )}
                        {loadingPay && <LoadingBox></LoadingBox>}

                        <PayPalButton
                          amount={order.totalPrice}
                          onSuccess={successPaymentHandler}
                        ></PayPalButton>
                      </>
                    )}
                  </li>
                )}
                {userInfo.isSeller && order.isPaid && !order.isDelivered && (
                  <li>
                    {loadingDeliver && <LoadingBox></LoadingBox>}
                    {errorDeliver && (
                      <MessageBox variant="danger">{errorDeliver}</MessageBox>
                    )}
                    <ColorButton
                      fullWidth
                      type="button"
                    
                      onClick={deliverHandler}
                    >
                      Deliver Order
                    </ColorButton>
                  </li>
                )}
              </ul>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
