import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { savePaymentMethod } from '../actions/cartActions';
import CheckoutSteps from '../components/CheckoutSteps';
import Container from '@material-ui/core/Container';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green, purple } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default function PaymentMethodScreen(props) {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  if (!shippingAddress.address) {
    props.history.push('/shipping');
  }
  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(purple[500]),
      size: "large",
      backgroundColor: purple[500],
      '&:hover': {
        backgroundColor: purple[700],
      },
    },
  }))(Button);
  const [paymentMethod, setPaymentMethod] = useState('PayPal');
  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    props.history.push('/placeorder');
  };
 
  const handleChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  return (
    <div>
      <CheckoutSteps step1 step2 step3></CheckoutSteps>
      <form  className="form" onSubmit={submitHandler}>
        <Card style={{marginTop:"32px"}}>
            <CardContent>
              <FormControl component="fieldset" >
              <FormLabel component="legend">Payment Method</FormLabel>
                  <RadioGroup aria-label="paymentMethod" name="paymentMethod" value={paymentMethod} onChange={handleChange}>
                        <FormControlLabel value="YOCO" control={<Radio />} label="YOCO" />
                        <FormControlLabel value="PayPal" control={<Radio />} label="PayPal" />
                        <FormControlLabel value="Stripe" control={<Radio />} label="Stripe" />
                      
                  </RadioGroup>    
              </FormControl>
              <div>    
                  <ColorButton fullWidth size="large"  type="submit">
                        Continue
                  </ColorButton>
              </div>
          </CardContent>
        </Card>
      </form> 
    </div>
  );
}
